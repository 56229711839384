<template>
  <div class="row">
    <div class="col-lg-6 offset-lg-3" style="position: fixed; height: 100vh;">
      <div
        style="position: absolute;
                left: 25%;
                top: 6vh;"
      >
        <img src="@/assets/images/elements/Rectangle.png">
      </div>
      <div
        style="position: absolute;
                left: 35.5%;
                top: 3vh;"
      >
        <img src="@/assets/images/elements/Rectangle-1.png">
      </div>
      <div
        style="position: absolute;
                right: 24.5%;
                bottom: 5vh"
      >
        <img src="@/assets/images/elements/Rectangle-2.png">
      </div>
      <div
        style="position: absolute;
                right: 21.5%;
                bottom: 2vh;"
      >
        <img src="@/assets/images/elements/Rectangle-3.png">
      </div>
      <div style="position: fixed; left: 0; right: 0; margin-left:auto; margin-right: auto; top: 15px">
        <div class="auth-wrapper auth-v1 px-2">
          <div class="auth-inner py-2">

            <!-- Register -->
            <b-card class="mb-0 pl-2 pr-2">
              <b-link class="brand-logo">
                <img
                  src="@/assets/petronas-logo.svg"
                  alt="Petronas Logo"
                >
              </b-link>

              <b-card-title class="mb-1">
                Sign Up
              </b-card-title>
              <b-card-text class="mb-2">
                Please sign up to manage your system easily
              </b-card-text>

              <!-- form -->
              <validation-observer ref="registerForm">
                <b-form
                  class="auth-register-form mt-2"
                  @submit.prevent="validationForm"
                >
                  <!-- username -->
                  <b-form-group
                    label="Username"
                    label-for="username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required"
                    >
                      <b-form-input
                        id="username"
                        v-model="username"
                        :state="errors.length > 0 ? false:null"
                        name="register-username"
                        placeholder="johndoe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="regEmail"
                        :state="errors.length > 0 ? false:null"
                        name="register-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- password -->
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          name="register-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Organization"
                    label-for="organization"
                  >
                    <b-form-select
                      v-model="organization"
                      :options="dropdown.allOrganization"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Role"
                    label-for="role"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-select
                        v-model="role"
                        :options="dropdown.allRole"
                      />
                    </b-input-group>
                  </b-form-group>
                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="status"
                      name="checkbox-1"
                    >
                      I agree to
                      <b-link>privacy policy & terms</b-link>
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button
                    class="button-signup"
                    variant="primary"
                    block
                    type="submit"
                  >
                    Sign up
                  </b-button>
                </b-form>
              </validation-observer>

              <b-card-text class="text-center mt-2">
                <span>Already have an account? </span>
                <router-link :to="{name:'login'}">
                  <span>Sign in instead</span>
                </router-link>
              </b-card-text>

            </b-card>
            <!-- /Register v1 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',
      organization: '',
      role: '',
      dropdown: {
        allOrganization: [{
          text: "Choose Organization",
          value: null,
          disabled: true,
        }],
        allRole: [{
          text: "Choose Role",
          value: null,
          disabled: true,
        }],
      },

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted(){
    this.getAllOrganization()
    this.getAllRole()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(() => {
        this.registerNewUser()
      })
    },
    getAllOrganization(){
      this.$axios.get(`${this.$baseUrl}/organizations/`).then(response => {
        const { data } = response
        data.forEach(item => {
          this.dropdown.allOrganization.push({
            text: item.name,
            value: item.id,
          })
        })
        this.organization = null
      })
    },
    getAllRole(){
      this.$axios.get(`${this.$baseUrl}/roles/`).then(response => {
        const { data } = response
        data.forEach(item => {
          this.dropdown.allRole.push({
            text: item.name,
            value: item.id,
          })
        })
        this.role = null
      })
    },
    registerNewUser(){
      const formData = new FormData()

      formData.append('username', this.username)
      formData.append('email', this.email)
      formData.append('password', this.password)
      formData.append('organization', this.organization)
      formData.append('role', this.role)

      // eslint-disable-next-line no-useless-concat
      this.$axios.post(`${this.$baseUrl}/register/`, formData).then(response => {
        if (response.data.code === 200) {
          this.$router.push({ name: 'login' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Info`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `${response.data.message}`,
              },
            })
          }).catch(() => {
            console.log('all good')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Info`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: `${response.data.message}`,
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.button-signup {
  background: linear-gradient(46.62deg, #00AE9D 0%, #93F9B9 93.64%);
  border-radius: 5px;
  border-style: none;
}
</style>
